<template>
  <router-view />
</template>

<script>
export default {
  name: "App",
  components: {},
  mounted() {},
};
</script>

<style lang="less">
#app {
  padding: 8px 16px 0;
  box-sizing: border-box;
  background-image: linear-gradient(to bottom, #91d5ff, #e6f7ff, white);
  min-height: 100vh;
}

.van-dialog .van-dialog__content .van-dialog__message p {
  text-indent: 2em !important;
  text-align: left !important;
}
</style>
