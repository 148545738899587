import router from './router'

router.beforeEach((to, from, next) => {
  let isIOS = function () {
    let isIphone = navigator.userAgent.includes('iPhone')
    let isIpad = navigator.userAgent.includes('iPad')
    return isIphone || isIpad
  }
  if (isIOS() && to.path !== location.pathname) {
    // 此处不可使用location.replace
    location.assign(to.fullPath)
  }
  next()
})