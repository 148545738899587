import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import Vant from "vant";
import 'amfe-flexible/index.js'
import "vant/lib/index.css";
import 'vant/es/toast/style';
import "@/permission";
import './assets/font_3914372_wrd7dt22mlf/iconfont.css'

const app = createApp(App)

app.use(router)
app.use(Vant)
app.mount('#app')

